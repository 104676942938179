<template>
  <div class="admin-ref_order">
    <div class="cell">
      <div class="title">退{{ orderText }}详情</div>
      <div class="conter">
        <div class="item">
          <div>退{{ orderText }}单号：</div>
          <div>{{ infoData.refund_no }}</div>
        </div>
        <div class="item">
          <div>商品名称：</div>
          <div>{{ infoData.goods_name }}</div>
        </div>
        <div class="item">
          <div>退{{ orderText }}金额：</div>
          <div>RM{{ infoData.price }}</div>
        </div>
        <div class="item">
          <div>退{{ orderText }}原因：</div>
          <div>{{ infoData.reason }}</div>
        </div>
        <div class="item">
          <div>退{{ orderText }}说明：</div>
          <div>{{ infoData.refund_content }}</div>
        </div>
        <div class="item">
          <div>卖家审核：</div>
          <div>{{ type[infoData.state] }}</div>
        </div>
        <div class="item">
          <div>商家备注：</div>
          <div>{{infoData.remark?infoData.remark:'无'}}</div>
        </div>
      </div>

      <el-button
        type="primary"
        @click="handleBack"
        size="mini"
        style="margin-top: 20px"
        >返回</el-button
      >
    </div>
  </div>
</template>
<script>
import { refOrderInfo } from "@/api/admin.js";
export default {
  data() {
    return {
      infoData: "",
      type: [
        "待审核",
        "卖家同意",
        "卖家拒绝",
        "申请平台介入",
        "成功退款",
        "退款已关闭",
      ],
      orderText: "货",
    };
  },
  created() {
    this.getInfo();
    if (this.$route.query.type) {
      this.orderText = this.$route.query.type == 1 ? "货" : "款";
      // this.type[0] = this.$route.query.type == 1 ? "申请退货" : "申请退款";
    }
  },
  methods: {
    getInfo() {
      refOrderInfo({
        refund_id: sessionStorage.ADMIN_ORDERID,
      }).then((res) => {
        if (res.code == 1) {
          this.infoData = res.data;
        }
      });
    },
    handleBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.admin-ref_order {
  .cell {
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    .title {
      font-size: 18px;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
    }
    .conter {
      .item {
        border-bottom: 1px solid #eee;
        font-size: 12px;
        // padding: 10px 0;
        line-height: 30px;
      }
      .item div:first-child {
        font-weight: bold;
      }
      .item div:last-child {
        font-size: 12px;
      }
    }
  }
}
</style>